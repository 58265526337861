import React from 'react';

import { faEdit, faFile, faFileCsv, faFileDownload, faFolder, faFolderMinus, faSignOutAlt, faTrashAlt, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import { isMobileOnly } from "react-device-detect";

import 'react-confirm-alert/src/react-confirm-alert.css';
import './Main.css';

class Main extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      currentPath: [],
      files: [],
      file: undefined,
      fileTimeout: undefined,
      newRow: undefined,
      fileUpload: undefined,
      filenameUpload: undefined,
    }
  }

  componentDidMount() {
    this.getFiles([]);
  }

  render() {
    return (
      <div id="main">
        <h1>Amministrazione</h1>
        <p>
          { this.props.user.email }
          <button onClick={() => {
            if(this.state.file !== undefined && this.state.newRow !== undefined && !this.state.newRow.values.every(nr => nr === "")) {
              confirmAlert({
                title: "Attenzione",
                message: "Ci sono modifiche non salvate, vuoi comunque eseguire il logout?",
                buttons: [
                  {
                    label: "No"
                  },
                  {
                    label: "Si, chiudi",
                    onClick: () => {
                      this.setState({
                        file: undefined,
                        newRow: undefined,
                      })
                      this.props.logout()
                    }
                  }
                ]
              })
            } else {
              confirmAlert({
                title: "Attenzione",
                message: "Eseguire il logout?",
                buttons: [
                  {
                    label: "No"
                  },
                  {
                    label: "Si, esci",
                    onClick: () => this.props.logout()
                  }
                ]
              })
            }
          }} title="Logout" style={{ backgroundColor: "lightcoral" }}><FontAwesomeIcon className="fa-fw" icon={ faSignOutAlt } /></button>
        </p>

        <h3>Navigazione</h3>
        { /* this.state.currentPath.map(p => {
          <a style={{ color: "gray", textDecoration: "underline", cursor: "pointer", padding: "10px" }} onClick={() => this.getFiles(p)}>{ p }</a>
        }) */ }
        { this.state.currentPath.length > 0 &&
          /* eslint-disable-next-line */
          <a className="link" style={{ padding: "10px" }} onClick={() => {
            if(this.state.file !== undefined && this.state.newRow !== undefined && !this.state.newRow.values.every(nr => nr === "")) {
              confirmAlert({
                title: "Attenzione",
                message: "Ci sono modifiche non salvate, vuoi chiudere il file?",
                buttons: [
                  {
                    label: "No"
                  },
                  {
                    label: "Si, chiudi",
                    onClick: () => {
                      this.setState({
                        file: undefined,
                        newRow: undefined,
                      })
                      this.getFiles(this.state.currentPath.slice(0, -1))
                    }
                  }
                ]
              })
            } else {
              this.setState({
                file: undefined,
                newRow: undefined,
              })
              this.getFiles(this.state.currentPath.slice(0, -1))
            }
          }}>
            <FontAwesomeIcon className="fa-fw" icon={ faFolderMinus } />
            ..
          </a>
        }
        { this.state.files.filter(f => !f.includes(".bak") || (this.props.user.structures === "w+:*" || this.props.user.structures.toUpperCase().indexOf("W+:"+this.state.currentPath[0].toUpperCase()) !== -1)).map((f,fi) => {
          const u = this.props.user

          if(f.includes(".csv")) {
            return <button key={fi} style={{ padding: "5px", margin: "10px" }} onClick={() => {
              if(this.state.fileTimeout !== undefined) {
                clearTimeout(this.state.fileTimeout)
                this.setState({
                  fileTimeout: undefined,
                })
              }
              this.getCsv(f)
              .then(() => window.scrollTo(0,document.body.scrollHeight))
            }} title="Apri file">
              <FontAwesomeIcon className="fa-fw" icon={ faFileCsv } />
              { f }
            </button>
          } else if(f.includes(".")) {
            return <button key={fi} style={{ padding: "5px", margin: "10px" }} onClick={() => {
              confirmAlert({
                title: "Conferma",
                message: "Vuoi scaricare il file "+f+"?",
                buttons: [
                  (u.structures === "w+:*" || u.structures.toUpperCase().indexOf("W+:"+this.state.currentPath[0].toUpperCase()) !== -1)
                  ? {
                    label: "No, elimina",
                    onClick: () => this.delFile(f)/* confirmAlert({
                      title: "Conferma",
                      message: "Vuoi eliminare il file "+f+"?",
                      buttons: [
                        {
                          label: "No"
                        },
                        {
                          label: "Si, elimina",
                          onClick: () => this.delFile(f)
                        },
                      ]
                    }) */,
                  }
                  : {
                    label: "No",
                  },
                  {
                    label: "Si, scarica",
                    onClick: () => window.open(
                      "https://acquaticasportromano.it:4001/file?path=" + (this.state.currentPath.length === 0 ? "" : (this.state.currentPath.length === 1 ? this.state.currentPath[0] : this.state.currentPath.join("/"))) + "/" + encodeURIComponent(f) + "&token="+this.props.user.token,
                      "_blank"
                    )
                  }
                ]
              })
            }} title="Scarica file">
              <FontAwesomeIcon className="fa-fw" icon={ faFile } />
              { f }
            </button>
          } else {
            return (u.structures === "r:*" || u.structures === "w:*" || u.structures === "w+:*" || u.structures.toUpperCase().indexOf(f.toUpperCase()) !== -1)
            /* eslint-disable-next-line */
            ? <a key={fi} className="link" style={{ padding: "10px" }} onClick={() => {
                this.getFiles(this.state.currentPath.concat(f))
              }}>
                <FontAwesomeIcon className="fa-fw" icon={ faFolder } />
                { f }
              </a>
            : null
          }
        }) }

        <h3>
          File { this.state.file !== undefined ? ": "+this.state.file.name+" " : "" }
          { this.state.file !== undefined &&
            [
              <button key={0} onClick={() => {
                if(this.state.newRow !== undefined && this.state.newRow.values.every(nr => nr === "")) {
                  this.setState({
                    file: undefined,
                    newRow: undefined,
                  })
                } else {
                  confirmAlert({
                    title: "Attenzione",
                    message: "La modifica non è stata salvata, chiudere comunque il file?",
                    buttons: [
                      {
                        label: "No"
                      },
                      {
                        label: "Si, chiudi",
                        onClick: () => {
                          if(this.state.fileTimeout !== undefined) {
                            clearTimeout(this.state.fileTimeout)
                          }
                          this.setState({
                            file: undefined,
                            newRow: undefined,
                            fileTimeout: undefined,
                          })
                        }
                      }
                    ]
                  })
                }
              }} title="Chiudi file" style={{ backgroundColor: "lightcoral" }}><FontAwesomeIcon className="fa-fw" icon={ faWindowClose } /></button>,
              <button key={1} onClick={() => {
                if(this.state.newRow.values.every(nr => nr === "")) {
                  window.open("https://acquaticasportromano.it:4001/file?path=" + (this.state.currentPath.length === 0 ? "" : (this.state.currentPath.length === 1 ? this.state.currentPath[0] : this.state.currentPath.join("/"))) + "/"+this.state.file.name + "&token="+this.props.user.token)
                } else {
                  confirmAlert({
                    title: "Attenzione",
                    message: "La modifica non è stata salvata, scaricare il file senza modifica?",
                    buttons: [
                      {
                        label: "No"
                      },
                      {
                        label: "Si, scarica",
                        onClick: () => window.open("https://acquaticasportromano.it:4001/file?path=" + (this.state.currentPath.length === 0 ? "" : (this.state.currentPath.length === 1 ? this.state.currentPath[0] : this.state.currentPath.join("/"))) + "/"+this.state.file.name + "&token="+this.props.user.token)
                      }
                    ]
                  })
                }
              }} title="Scarica file" style={{ backgroundColor: "lightblue" }}><FontAwesomeIcon className="fa-fw" icon={ faFileDownload } /></button>
            ]
          }
        </h3>
        { this.state.file === undefined
          ? (
            this.state.currentPath.length > 0
              ? <div>
                { (this.props.user.structures === "w:*" || this.props.user.structures === "w+:*" || this.props.user.structures.toUpperCase().indexOf("W:"+this.state.currentPath[0].toUpperCase()) !== -1 || this.props.user.structures.toUpperCase().indexOf("W+:"+this.state.currentPath[0].toUpperCase()) !== -1)
                  ? <form onSubmit={e => {
                    e.preventDefault()

                    confirmAlert({
                      title: "Conferma",
                      message: this.state.filenameUpload === undefined ? "Vuoi caricare il nuovo file "+this.state.fileUpload.name+"?" : "Vuoi sovrascrivere il file "+this.state.filenameUpload+"?",
                      buttons: [
                        {
                          label: "No"
                        },
                        {
                          label: this.state.filenameUpload === undefined ? "Si, carica" : "Si, sovrascrivi",
                          onClick: () => {
                            this.setState({
                              loading: true
                            })
                        
                            const file = this.state.fileUpload
                            const filename = this.state.filenameUpload !== undefined ? this.state.filenameUpload : file.name
                            const path = this.state.currentPath.length === 0 ? "" : (this.state.currentPath.length === 1 ? this.state.currentPath[0] : this.state.currentPath.join("/"))
                        
                            let formData = new FormData()
                            formData.append('file', file/* .data */)
                            formData.append('path', path)
                            formData.append('filename', filename)
                            formData.append('user', this.props.user.id)
                            fetch('https://acquaticasportromano.it:4001/file', {
                              method: 'POST',
                              headers: {
                                Accept: 'application/text',
                                authorization: `Bearer ${this.props.user.token}`,
                              },
                              body: formData,
                            }).then(response => {
                              if(response.status === 200) {
                                confirmAlert({
                                  title: "Fatto",
                                  message: "File caricato correttamente",
                                  buttons: [
                                    {
                                      label: "OK",
                                      onClick: () => this.getFiles(this.state.currentPath)
                                    }
                                  ]
                                })
                              } else {
                                response.text().then(responseText => {
                                  if(responseText.includes("TokenExpiredError")) {
                                    window.reload()
                                  } else {
                                    confirmAlert({
                                      title: "Errore",
                                      message: responseText,
                                      buttons: [
                                        {
                                          label: "OK"
                                        }
                                      ]
                                    })
                                  }
                                })
                              }
                              this.setState({
                                loading: false,
                              })
                            }).catch((error) => {
                              this.setState({
                                loading: false
                              })
                              if(error.toString().indexOf("Failed to fetch") !== -1) {
                                confirmAlert({
                                  title: "Errore",
                                  message: "Server non raggiungibile: verifica di essere connesso ad internet!",
                                  buttons: [
                                    {
                                      label: "OK"
                                    }
                                  ]
                                })
                              } else {
                                confirmAlert({
                                  title: "Errore",
                                  message: error.toString(),
                                  buttons: [
                                    {
                                      label: "OK"
                                    }
                                  ]
                                })
                              }
                              this.setState({
                                loading: false,
                              })
                            })
                          }
                        }
                      ],
                    })
                  }}>
                    <input type="file" name="upload" onChange={e => {
                      const file = e.target.files[0]/* {
                        preview: URL.createObjectURL(e.target.files[0]),
                        data: e.target.files[0],
                      } */
                      this.setState({
                        fileUpload: file
                      })
                    }} />
                    <select onChange={e => this.setState({
                      filenameUpload: e.target.value !== "-- Seleziona per sovrascrivere --" ? e.target.value : undefined
                    })}>
                      <option value={undefined}>-- Seleziona per sovrascrivere --</option>
                      { this.state.files.filter(f => !f.includes(".bak")).map((f,fi) => {
                        if(f.includes(".")) {
                          return <option key={fi} value={f}>{f}</option>
                        }
                        return null
                      })}
                    </select>
                    <input type="submit" name="Upload" />
                  </form>
                  : <p>Non puoi caricare file</p>
                }
              </div>
              : <p>Apri una cartella</p>
          )
          : (!isMobileOnly
            ? <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
              { this.state.file !== undefined && this.state.file.rows.length > 0 &&
                [this.state.file.rows[0]].map((r, ri) => {
                  const keys = Object.keys(r)

                  return <span key={ri} style={{ flex: 1, width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
                    {
                      keys.map((k, ki) => {
                        return <p key={ki} style={{ flex: 1, fontWeight: "bold" }}>{k}</p>
                      })
                    }
                    <p style={{ flex: 1, fontWeight: "bold", color: "yellow" }}>Azioni</p>
                  </span>
                })
              }
              { this.state.file !== undefined && this.state.file.rows.length > 0 &&
                this.state.file.rows.map((r, ri) => {
                  const keys = Object.keys(r)
                  const values = Object.values(r)

                  if(this.state.newRow !== undefined && this.state.newRow.index === ri) {
                    return <span key={ri} style={{ flex: 1, width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
                    {
                      keys.map((k, ki) => {
                        return <input key={ki} style={{ flex: 1, width: "100px" }} onChange={e => {
                          const newRow = {
                            index: ri,
                            values: this.state.newRow.values.map((nr, nri) => {
                              if(ki === nri)
                                return e.target.value
                              return nr
                            })
                          }
                          this.setState({
                            newRow: newRow
                          })
                        }} value={this.state.newRow.values[ki]} placeholder={k} />
                      })
                    }
                    <p style={{ flex: 1 }}>
                      { this.state.file !== undefined && this.state.file.rows.length > 0 && this.state.newRow.values.some(nr => nr !== "") &&
                        <button onClick={() => {
                          const keys = Object.keys(this.state.file.rows[0])
                          const newFileRow = {}
                          keys.forEach((k, ki) => {
                            newFileRow[k] = this.state.newRow.values[ki]
                          })
                          const newFile = {
                            name: this.state.file.name,
                            rows: this.state.newRow.index === undefined ? this.state.file.rows.concat(newFileRow) : this.state.file.rows.map((fr, fri) => {
                              if(fri === this.state.newRow.index)
                                return newFileRow
                              return fr
                            })
                          }
                          this.postCsv(newFile)
                        }}>Salva riga</button>
                      }
                    </p>
                  </span>
                  } else {
                    return <span key={ri} style={{ flex: 1, width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
                      {
                        values.map((v, vi) => {
                          return <p key={vi} style={{ flex: 1, width: "100px" }}>{v}</p>
                        })
                      }
                      <p style={{ flex: 1, width: "100px" }}>
                        { !(this.state.file.rows.length === 1 && ri === 0) &&
                          <button onClick={() => {
                            this.setState({
                              newRow: {
                                index: ri,
                                values: Object.values(r)
                              }
                            })
                          }} title="Modifica riga">
                            <FontAwesomeIcon className="fa-fw" icon={faEdit} />
                          </button>
                        }
                        { !(this.state.file.rows.length === 1 && ri === 0) &&
                          <button onClick={() => {
                            const newFile = {
                              name: this.state.file.name,
                              rows: this.state.file.rows.filter((fr, fri) => fri !== ri)
                            }
                            this.postCsv(newFile)
                          }} title="Elimina riga">
                            <FontAwesomeIcon className="fa-fw" icon={faTrashAlt} />
                          </button>
                        }
                      </p>
                    </span>
                  }
                })
              }
              { this.state.file !== undefined && this.state.file.rows.length > 0 && this.state.newRow.index === undefined &&
                <span>Nuova riga:</span>
              }
              { this.state.file !== undefined && this.state.file.rows.length > 0 && this.state.newRow.index === undefined &&
                [this.state.file.rows[0]].map((r, ri) => {
                  const keys = Object.keys(r)

                  return <span key={ri} style={{ flex: 1, width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
                    {
                      keys.map((k, ki) => {
                        return <input key={ki} style={{ flex: 1, width: "100px" }} onChange={e => {
                          const newRow = {
                            index: undefined,
                            values: this.state.newRow.values.map((nr, nri) => {
                              if(ki === nri)
                                return e.target.value
                              return nr
                            })
                          }
                          this.setState({
                            newRow: newRow
                          })
                        }} value={this.state.newRow.values[ki]} placeholder={k} />
                      })
                    }
                    <p style={{ flex: 1 }}>
                      { this.state.file !== undefined && this.state.file.rows.length > 0 && this.state.newRow.values.some(nr => nr !== "") &&
                        <button onClick={() => {
                          const keys = Object.keys(this.state.file.rows[0])
                          const newFileRow = {}
                          keys.forEach((k, ki) => {
                            newFileRow[k] = this.state.newRow.values[ki]
                          })
                          const newFile = {
                            name: this.state.file.name,
                            rows: this.state.newRow.index === undefined ? this.state.file.rows.concat(newFileRow) : this.state.file.rows.slice(0, this.state.newRow.index).concat(newFileRow, this.state.file.rows.slice(this.state.newRow.index, this.state.file.rows.length-1))
                          }
                          this.postCsv(newFile).then(() => window.scrollTo(0,document.body.scrollHeight))
                        }}>Aggiungi riga</button>
                      }
                    </p>
                  </span>
                })
              }
              { this.state.file !== undefined && this.state.file.rows.length > 1 && 
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                  <h3>TOTALI</h3>

                  { this.state.file !== undefined && this.state.file.rows.length > 0 &&
                    [this.state.file.rows[0]].map((r, ri) => {
                      const keys = Object.keys(r)

                      return <span key={ri} style={{ flex: 1, width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-evenly" }}>
                        {
                          keys.map((k, ki) => {
                            let tot = 0;
                            try {
                              this.state.file.rows.forEach((r2, r2i) => {
                                const data = Object.values(r2)[ki]
                                const number = Number.parseFloat(data.replace(/ /g, "").replace(/\./g, "").replace(/,/g, "."))
                                if(!isNaN(number)) {
                                  tot += number
                                }
                              })
                            } catch(err) {}
                            return <p key={ki} style={{ flex: 1, fontWeight: "bold" }}>{(tot.toFixed(0) === "0" || k.toLowerCase() === "data") ? "" : tot.toFixed(2).replace(".", ",")}</p>
                          })
                        }
                        <p style={{ flex: 1 }}>
                          Dati aggiornati:<br />
                          {new Date(this.state.file.datetime).toLocaleString()}
                        </p>
                      </span>
                    })
                  }
                </div>
              }
            </div>
            : <p>Modifiche live disponibili solo via computer.</p>
          )
        }
      </div>
    )
  }

  async getFiles(path) {
    this.setState({
      loading: true
    });
  
    const url = "https://acquaticasportromano.it:4001/files?path=" + (path.length === 0 ? "" : (path.length === 1 ? path[0] : path.join("/")))
    return fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        authorization: `Bearer ${this.props.user.token}`
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      this.setState({
        files: responseJson,
        currentPath: path,
        loading: false,
      })
    })
    .catch((error) => {
      this.setState({
        loading: false
      })
      if(error.toString().indexOf("Failed to fetch") !== -1) {
        confirmAlert({
          title: "Errore",
          message: "Server non raggiungibile: verifica di essere connesso ad internet!",
          buttons: [
            {
              label: "OK"
            }
          ]
        })
      } else {
        confirmAlert({
          title: "Errore",
          message: error.toString(),
          buttons: [
            {
              label: "OK"
            }
          ]
        })
      }
      this.setState({
        loading: false,
      })
    })
  }

  async delFile(filename) {
    this.setState({
      loading: true
    })
  
    return fetch("https://acquaticasportromano.it:4001/file?path=" + (this.state.currentPath.length === 0 ? "" : (this.state.currentPath.length === 1 ? this.state.currentPath[0] : this.state.currentPath.join("/")))+"/"+filename + "&user="+this.props.user.id, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        authorization: `Bearer ${this.props.user.token}`
      }
    })
    .then((response) => {
      if(response.status === 200) {
        confirmAlert({
          title: "Fatto",
          message: "File eliminato correttamente",
          buttons: [
            {
              label: "OK",
              onClick: () => this.getFiles(this.state.currentPath)
            }
          ]
        })
      } else {
        response.text().then(responseText => {
          if(responseText.includes("TokenExpiredError")) {
            window.reload()
          } else {
            confirmAlert({
              title: "Errore",
              message: responseText,
              buttons: [
                {
                  label: "OK"
                }
              ]
            })
          }
        })
      }
      this.setState({
        loading: false,
      })
    })
    .catch((error) => {
      this.setState({
        loading: false
      })
      if(error.toString().indexOf("Failed to fetch") !== -1) {
        confirmAlert({
          title: "Errore",
          message: "Server non raggiungibile: verifica di essere connesso ad internet!",
          buttons: [
            {
              label: "OK"
            }
          ]
        })
      } else {
        confirmAlert({
          title: "Errore",
          message: error.toString(),
          buttons: [
            {
              label: "OK"
            }
          ]
        })
      }
    })
  }

  async getCsv(filename) {
    this.setState({
      loading: true
    })
  
    return fetch("https://acquaticasportromano.it:4001/csv?path=" + (this.state.currentPath.length === 0 ? "" : (this.state.currentPath.length === 1 ? this.state.currentPath[0] : this.state.currentPath.join("/"))) + "/"+filename, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        authorization: `Bearer ${this.props.user.token}`
      }
    })
    .then((response) => response.json())
    .then((responseJson) => {
      this.setState({
        fileTimeout: setTimeout(() => this.getCsv(filename), 10000),
      })
      if(this.state.file === undefined || this.state.newRow.values.every(nr => nr === "")) {
        this.setState({
          file: responseJson,
          newRow: {
            index: undefined,
            values: [].concat(Object.keys(responseJson.rows[0]).map(() => ""))
          },
        })
      }
    })
    .catch((error) => {
      this.setState({
        loading: false
      })
      if(error.toString().indexOf("Failed to fetch") !== -1) {
        confirmAlert({
          title: "Errore",
          message: "Server non raggiungibile: verifica di essere connesso ad internet!",
          buttons: [
            {
              label: "OK"
            }
          ]
        })
      } else {
        confirmAlert({
          title: "Errore",
          message: error.toString(),
          buttons: [
            {
              label: "OK"
            }
          ]
        })
      }
    })
  }

  async postCsv(file) {
    this.setState({
      loading: true
    })
  
    return fetch("https://acquaticasportromano.it:4001/csv", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Accept: 'application/json',
        authorization: `Bearer ${this.props.user.token}`
      },
      body: JSON.stringify({
        path: (this.state.currentPath.length === 0 ? "" : (this.state.currentPath.length === 1 ? this.state.currentPath[0] : this.state.currentPath.join("/"))) + "/" + file.name,
        results: file.rows
      })
    })
    .then((response) => {
      if(response.status === 200) {
        this.setState({
          newRow: {
            index: undefined,
            values: [].concat(Object.keys(this.state.file.rows[0]).map(() => ""))
          },
        })
        this.getCsv(file.name)
      }
    })
    .catch((error) => {
      this.setState({
        loading: false
      })
      if(error.toString().indexOf("Failed to fetch") !== -1) {
        confirmAlert({
          title: "Errore",
          message: "Server non raggiungibile: verifica di essere connesso ad internet!",
          buttons: [
            {
              label: "OK"
            }
          ]
        })
      } else {
        confirmAlert({
          title: "Errore",
          message: error.toString(),
          buttons: [
            {
              label: "OK"
            }
          ]
        })
      }
    })
  }
}

export default Main;