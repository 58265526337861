import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { confirmAlert } from 'react-confirm-alert';

import 'react-confirm-alert/src/react-confirm-alert.css';
import './Login.css';

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      email: "",
      pwd: "",
      confirmPwd: "",
      register: false,
      reset: false,
    }
  }

  componentDidMount() {
    if(localStorage.getItem("login") !== null) {
      const login = JSON.parse(localStorage.getItem("login"))
      
      if(login.email !== undefined && login.token !== undefined) {
        this.login(login.email, login.token)
      }
    }
  }

  render() {
    return (
      <div id="login">
        <h1>{ this.state.register ? "Registrati" : ( this.state.reset ? "Reset" : "Accedi" )}</h1>

        <form onSubmit={e => {
          e.preventDefault();
          if(this.state.register) {
            this.register();
          } else if(this.state.reset) {
            this.reset();
          } else {
            this.auth(this.state.email, this.state.pwd);
          }
        }}>
          <input type="email" value={ this.state.email } onChange={e => this.setState({
            email: e.target.value.toLowerCase().replace(/ /g, "")
          })} placeholder="Email" />
          {/* { (this.state.email !== "" || this.state.register || this.state.reset) &&  */}<input type="password" value={ this.state.pwd } onChange={e => this.setState({
            pwd: e.target.value
          })} placeholder={ this.state.reset ? "Nuova password" : "Password" } /> {/*  } */}
          { (this.state.register || this.state.reset) &&
            <input type="password" value={ this.state.confirmPwd } onChange={e => this.setState({
              confirmPwd: e.target.value
            })} placeholder="Conferma password" />
          }
          { !this.state.loading
            ? <input type="submit" value={ this.state.register || this.state.reset ? "Conferma" : "Entra" } />
            : <p><FontAwesomeIcon className="fa-fw fa-spin" icon={ faCircleNotch } /></p>
          }
        </form>
        {/* eslint-disable-next-line */}
        { !this.state.loading && !this.state.reset && <a className="link" onClick={() => this.setState({
          register: ! this.state.register
        })} style={{ marginTop: "30px" }}>{ this.state.register ? "Indietro" : "Non ho un account!" }</a>}

        {/* eslint-disable-next-line */}
        { !this.state.loading && !this.state.register && <a className="link" onClick={() => this.setState({
          reset: ! this.state.reset
        })} style={{ marginTop: "10px" }}>{ this.state.reset ? "Indietro" : "Reimposta password!" }</a>}
      </div>
    );
  }

  async login(e, t) {
    fetch('https://acquaticasportromano.it:4001/user?email='+e, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        authorization: `Bearer ${t}`
      },
    })
    .then((response) => {
      if(response.status === 200) {
        response.json().then(responseJson => {
          localStorage.setItem("login", JSON.stringify({
            email: e,
            token: t,
          }))

          const user = responseJson.user
          user.token = t
          this.props.login(user)
        })
      } else {
        this.setState({
          loading: false
        })
        response.text().then(responseText => {
          if(! responseText.includes("TokenExpiredError")) {
            confirmAlert({
              title: "Errore",
              message: responseText,
              buttons: [
                {
                  label: "OK"
                }
              ]
            })  
          }
        })
      }
    })
    .catch((error) => {
      this.setState({
        loading: false
      })
      if(error.toString().indexOf("Failed to fetch") !== -1) {
        confirmAlert({
          title: "Errore",
          message: "Server non raggiungibile: verifica di essere connesso ad internet!",
          buttons: [
            {
              label: "OK"
            }
          ]
        })
      } else {
        confirmAlert({
          title: "Errore",
          message: error.toString(),
          buttons: [
            {
              label: "OK"
            }
          ]
        })
      }
      this.setState({
        loading: false,
      })
    })
  }

  async auth(e, p) {
    this.setState({
      loading: true
    })

    fetch('https://acquaticasportromano.it:4001/authenticate', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: e,
        pwd: p,
      }),
    })
    .then((response) => {
      if(response.status === 200) {
        response.json().then(responseJson => {
          const token = responseJson.token

          this.login(e, token)
        })
      } else {
        this.setState({
          loading: false
        })
        response.text().then(responseText => confirmAlert({
          title: "Errore",
          message: responseText,
          buttons: [
            {
              label: "OK"
            }
          ]
        }))
      }
    })
    .catch((error) => {
      this.setState({
        loading: false
      })
      if(error.toString().indexOf("Failed to fetch") !== -1) {
        confirmAlert({
          title: "Errore",
          message: "Server non raggiungibile: verifica di essere connesso ad internet!",
          buttons: [
            {
              label: "OK"
            }
          ]
        })
      } else {
        confirmAlert({
          title: "Errore",
          message: error.toString(),
          buttons: [
            {
              label: "OK"
            }
          ]
        })
      }
    })
  }

  async register() {
    this.setState({
      loading: true
    })

    fetch('https://acquaticasportromano.it:4001/ask-register', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: this.state.email,
        pwd: this.state.pwd,
      }),
    })
    .then((response) => {
      if(response.status === 200) {
        confirmAlert({
          title: "Richiesta inoltrata",
          message: "Richiesta di registrazione inoltrata al responsabile! In caso di accettazione riceverà un'email di conferma.",
          buttons: [
            {
              label: "OK",
            }
          ],
          willUnmount: () => this.setState({
            loading: false,
            register: false
          })
        })
      } else {
        this.setState({
          loading: false
        })
        response.text().then(responseText => confirmAlert({
          title: "Errore",
          message: responseText,
          buttons: [
            {
              label: "OK"
            }
          ]
        }))
      }
    })
    .catch((error) => {
      this.setState({
        loading: false
      })
      if(error.toString().indexOf("Failed to fetch") !== -1) {
        confirmAlert({
          title: "Errore",
          message: "Server non raggiungibile: verifica di essere connesso ad internet!",
          buttons: [
            {
              label: "OK"
            }
          ]
        })
      } else {
        confirmAlert({
          title: "Errore",
          message: error.toString(),
          buttons: [
            {
              label: "OK"
            }
          ]
        })
      }
    })
  }

  async reset() {
    this.setState({
      loading: true
    })

    fetch('https://acquaticasportromano.it:4001/ask-reset', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: this.state.email,
        pwd: this.state.pwd,
      }),
    })
    .then((response) => {
      if(response.status === 200) {
        confirmAlert({
          title: "Email inviata",
          message: "Ti abbiamo inviato una mail di conferma!",
          buttons: [
            {
              label: "OK",
            }
          ],
          willUnmount: () => this.setState({
            loading: false,
            reset: false
          })
        })
      } else {
        this.setState({
          loading: false
        })
        response.text().then(responseText => confirmAlert({
          title: "Errore",
          message: responseText,
          buttons: [
            {
              label: "OK"
            }
          ]
        }))
      }
    })
    .catch((error) => {
      this.setState({
        loading: false
      })
      if(error.toString().indexOf("Failed to fetch") !== -1) {
        confirmAlert({
          title: "Errore",
          message: "Server non raggiungibile: verifica di essere connesso ad internet!",
          buttons: [
            {
              label: "OK"
            }
          ]
        })
      } else {
        confirmAlert({
          title: "Errore",
          message: error.toString(),
          buttons: [
            {
              label: "OK"
            }
          ]
        })
      }
    })
  }
}

export default Login;