import React from 'react';
import { isMobileOnly } from 'react-device-detect';

import Login from './components/Login';
import Main from './components/Main';

import logo from './logo.jpg';
import logo192 from './logo192.png';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null/* {
        id: 0,
        name: "Andrea Rossini"
      } */
    }
  }

  render() {
    return (
      <div id="app">
        <div id="header">
        <img src={ !isMobileOnly ? logo : logo192 } alt="" />
          <h1>Corrispettivi</h1>
        </div>
        <div id="body">
          { this.state.user !== null
            ? <Main user={ this.state.user } logout={() => {
                localStorage.removeItem("login")
                this.setState({
                  user: null
                })
              }} />
            : <Login login={u => this.setState({
              user: u
            })} />
          }
        </div>
      </div>
    );
  }
}

export default App;
